// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-basepage-js": () => import("./../src/templates/basepage.js" /* webpackChunkName: "component---src-templates-basepage-js" */),
  "component---src-templates-craftwork-js": () => import("./../src/templates/craftwork.js" /* webpackChunkName: "component---src-templates-craftwork-js" */),
  "component---src-templates-craftwork-list-js": () => import("./../src/templates/craftwork-list.js" /* webpackChunkName: "component---src-templates-craftwork-list-js" */),
  "component---src-templates-portfolio-js": () => import("./../src/templates/portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-js" */),
  "component---src-templates-portfolio-list-js": () => import("./../src/templates/portfolio-list.js" /* webpackChunkName: "component---src-templates-portfolio-list-js" */),
  "component---src-templates-portfolio-slider-js": () => import("./../src/templates/portfolio-slider.js" /* webpackChunkName: "component---src-templates-portfolio-slider-js" */)
}

